@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';

/** your custom css code **/
/* 
html {
    background-color: $theme-color-1 !important;
}
*/
.text-initial {
    text-align: initial !important;
}

.logo-white {
    width: 120px;
}

.react-date-picker__button {
    padding: 0 4px !important; 
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-calendar__tile--now {
    background: #008ecc !important;
    color: white !important;
}

